<template>
  <div class="home-container detail">
    <div class="content">
      <div class="content-all">
        <h2 class="title">{{ caseObj.title }}</h2>
        <p class="date">发布时间: {{ caseObj.publishTime }}</p>
      </div>
      <div class="content-data">
        <p v-html="caseObj.content"></p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "caseDetail",
  data() {
    return {
      message: {},
      content: "",
    };
  },
  computed: mapState({
    caseObj: (state) => state.caseDetail.caseObj,
  }),
  created() {
    if (this.$route.query.data) {
      this.message = JSON.parse(this.$route.query.data);
      console.log(this.message);
    }
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  margin: 0 auto;
  border-radius: 5px;
  padding-bottom: 20px;
  border-top: none;
  .content-all {
    width: 100%;
    height: 23%;
    border: 1px solid #e5e5e5;
    h2 {
      font-size: 20px;
      margin-top: 15px;
      padding-top: 10px;
      text-align: center;
    }
    .date {
      font-size: 14px;
      text-align: center;
      color: #bbb;
      margin-top: 6px;
      margin-bottom: 6px;
    }
  }
  .content-data {
    font-size:16px;
    width: 100%;
    height: 500px;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    overflow-y: auto;
    padding: 20px 20px 20px 20px;
    word-wrap: break-word;
    white-space: normal;
    word-break: break-all;
  }
  .content-data::-webkit-scrollbar {
    width: 5px;
    height: 4px;
  }
  .content-data::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #e5e5e5;
  }
  .content-data::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px #e5e5e5;
    border-radius: 0;
    background: #e5e5e5;
  }
  .footer {
    width: 100%;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid#e5e5e5;
    .next {
      float: left;
      margin-left: 30px;
      font-size: 12px;
    }
    .last {
      float: right;
      margin-right: 30px;
      font-size: 12px;
    }
  }
}
.detail {
  margin-top: 60px;
}
.detail > div:first-child {
  margin-bottom: 15px;
  text-align: right;
}
.detail img {
  width: 100%;
  height: 300px;
}
</style>